import { GoogleOAuthProvider } from '@react-oauth/google';
import React from "react";
import { IntlProvider } from 'react-intl';
import Loadable from "react-loadable";
import 'react-toastify/dist/ReactToastify.css';
import "./App.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/hhwt.css";
import "./assets/css/style.css";
import ToastMessage from "./components/toastify";
import enUS from './i18n/messages/en-US';

const AsyncComponent = Loadable({
  loader: () => import("./commonlinks"),
  loading: () => (
    <div
      style={{
        textAlign: "center",
        position: "absolute",
        top: "50%",
        width: "100%",
        fontSize: "48px",
        fontWeight: "500",
        letterSpacing: "3px",
        color: "#00cccb"
      }}
    >
      <span>loading...</span>
    </div>
  )
});

function App() {
  return (
    <div className="App">
      <IntlProvider
        messages={enUS}
        locale={"en-SG"}
      >
        <GoogleOAuthProvider
          clientId={process.env.REACT_APP_GG_CLIENT_ID}
        >
          <AsyncComponent />
          <ToastMessage />
        </GoogleOAuthProvider>
      </IntlProvider>
    </div>
  );
}

export default App;
