import React from 'react';
import { toast, ToastContainer } from 'react-toastify';

function ToastMessage() {
  return (
    <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover={false}
      theme="light"
    />
  )
}

export default ToastMessage;

export const ToastSuccess = (message) => toast.success(message);
export const ToastError = (message) => toast.error(message);